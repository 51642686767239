export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z'
];

export const TAG_TYPE_MODEL = {
  OBJECTIVE: 'Objetiva',
  SUBJECTIVE: 'Discursiva',
  RELATE_COLUMNS: 'Relacionar colunas',
  SUMMATION: 'Somatório',
  TRUTHY_OR_FALSE: 'Verdadeiro ou Falso'
};

export const TAG_TYPES = {
  MODEL: 1,
  DIFFICULTY: 2,
  KEYWORD: 3,
  OTHERS: 4,
  STATE: 5,
  REGION: 6,
  YEAR: 7,
  APPLICATION: 8,
  TEST_BOARD: 9,
  OBJECTIVE_ANSWER: 10
};

export const TYPE_RESOLUTION = {
  OBJECTIVE: 1,
  TRUTHY_FALSY: 2,
  SUMMATION: 3,
  RELATE_COLUMNS: 4
};

export const NAME_TAG = {
  DEFAULT: 'Não Possui'
};

export const CLASS_CORRECT_QUESTION = '--correct';
export const CLASS_INCORRECT_QUESTION = '--incorrect';

export const TRUTHY = 'V';
export const FALSY = 'F';

export const MAXIMUM_FONT_SIZE = 18;
export const MINIMUM_FONT_SIZE = 10;
