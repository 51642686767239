










































import { Component, Vue, Prop } from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { IRelateColumnsAnswers, IRelateColumnsAlternative } from '@/globalInterfaces/Questions';

import { replaceText } from '@/share/Util/Text/index';

import {
  CLASS_CORRECT_QUESTION,
  CLASS_INCORRECT_QUESTION,
  MAXIMUM_FONT_SIZE,
  MINIMUM_FONT_SIZE
} from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeRelateColumnsResult extends Vue {
  @Prop({ default: 14 }) fontSizeProp!: number;
  @Prop({ default: true }) showContent!: boolean;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  get shuffledAlternatives() {
    const alternatives = this.$store.getters.shuffledAlternativesRelateColumns;

    if (!this.showContent) {
      alternatives.content = replaceText(alternatives.content);
    }

    return alternatives;
  }

  get shuffledResponses() {
    let result = this.$store.getters.answersRelateColumns;

    if (!this.showContent) {
      result = result.map((answer: any) => ({
        ...answer,
        response: replaceText(answer.response)
      }));
    }

    return result;
  }

  setColor(answer: IRelateColumnsAnswers) {
    const alternativeFound: IRelateColumnsAlternative | undefined = this.shuffledAlternatives.find(
      (alternative: IRelateColumnsAlternative) => alternative.ID === answer.optionID
    );

    if (!alternativeFound) return CLASS_INCORRECT_QUESTION;

    return alternativeFound.index === answer.selectedIndex
      ? CLASS_CORRECT_QUESTION
      : CLASS_INCORRECT_QUESTION;
  }
}
