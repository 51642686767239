



































import { Component, Vue, Prop } from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { replaceText } from '@/share/Util/Text/index';

import {
  CLASS_CORRECT_QUESTION,
  CLASS_INCORRECT_QUESTION,
  MAXIMUM_FONT_SIZE,
  MINIMUM_FONT_SIZE
} from '@/constant/Question';

@Component({
  components: {
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeSummationResult extends Vue {
  @Prop({ default: 14 }) fontSizeProp!: number;
  @Prop({ default: true }) showContent!: boolean;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  get shuffledAlternatives() {
    return this.$store.getters.summationQuestion.shuffledAlternatives;
  }

  get summationAnswer() {
    if (!this.showContent) {
      return replaceText(this.$store.getters.summationQuestion.answer);
    }

    return this.$store.getters.summationQuestion.answer;
  }

  get summationRightAnswer() {
    return this.$store.getters.summationQuestion.rightAnswer;
  }

  get setColor() {
    return this.summationAnswer === this.summationRightAnswer
      ? CLASS_CORRECT_QUESTION
      : CLASS_INCORRECT_QUESTION;
  }
}
