




























import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import LightBoxImage from '@/mixins/LightBoxImage';

import { replaceText } from '@/share/Util/Text/index';

@Component({
  mixins: [LightBoxImage]
})
export default class RadioAlternativeResults extends Vue {
  @Prop({ required: true }) radioList!: Array<Record<string, any>>;
  @Prop({ default: true }) showContent!: boolean;

  private data: Array<Record<string, any>> = [];

  get listRadio() {
    if (!this.showContent) {
      return this.data.map((item: Record<string, any>) => ({
        ...item,
        title: replaceText(item.title)
      }));
    }

    return this.data;
  }

  setColor(option: Record<string, any>) {
    let className = '';

    if (option?.correct === 1) {
      className = '--correct';
      return className;
    }

    if (option?.answerOptionID === option?.idOptions) {
      className = '--incorrect';
    }

    return className;
  }

  optimizeImageLoadingInAlternative(alternative: string) {
    const newContentAlternative = alternative.replace('<img ', '<img loading="lazy" ');
    return newContentAlternative;
  }

  @Watch('radioList', {
    immediate: true
  })
  newData(): void {
    if (this.radioList && this.radioList.length) {
      this.data = this.radioList.map((item: object) => ({
        ...item,
        isAble: true
      }));
    }
  }
}
