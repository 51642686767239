

































import { Component, Vue, Prop } from 'vue-property-decorator';

import Arrow from '@/assets/icons/Arrow.vue';

import LightBoxImage from '@/mixins/LightBoxImage';

import AlternativeIndex from '../AlternativeIndex/index.vue';

import { OptionsAnswerTrueOrFalse } from '@/pages/Question/interface';

import { replaceText } from '@/share/Util/Text/index';

import {
  CLASS_CORRECT_QUESTION,
  CLASS_INCORRECT_QUESTION,
  TRUTHY,
  FALSY,
  MAXIMUM_FONT_SIZE,
  MINIMUM_FONT_SIZE
} from '@/constant/Question';

@Component({
  components: {
    Arrow,
    AlternativeIndex
  },
  mixins: [LightBoxImage]
})
export default class AlternativeTruthyOrFalsyResult extends Vue {
  @Prop({ required: true }) alternatives!: OptionsAnswerTrueOrFalse[];
  @Prop({ default: 14 }) fontSizeProp!: number;
  @Prop({ default: true }) showContent!: boolean;

  get fontSize() {
    if (this.fontSizeProp >= MAXIMUM_FONT_SIZE) return MAXIMUM_FONT_SIZE;
    if (this.fontSizeProp <= MINIMUM_FONT_SIZE) return MINIMUM_FONT_SIZE;
    return this.fontSizeProp;
  }

  setColor(option: OptionsAnswerTrueOrFalse) {
    return option.answerOption === option.validated
      ? CLASS_CORRECT_QUESTION
      : CLASS_INCORRECT_QUESTION;
  }

  setAnswer(option: OptionsAnswerTrueOrFalse) {
    if (option.answerOption === null) return '(   )';

    const result = option.answerOption === 1 ? TRUTHY : FALSY;

    if (!this.showContent) {
      return replaceText(result);
    }

    return result;
  }
}
