




















import { Component, Vue, Prop } from 'vue-property-decorator';

import { ALPHABET } from '@/constant/Question';

@Component
export default class AlternativeIndex extends Vue {
  @Prop({ required: false }) index!: number;
  @Prop({ required: false }) value!: number | string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) isSummation!: boolean;
  @Prop({ default: false }) isNumeric!: boolean;

  private alphabet = ALPHABET;

  setAlternativeNumberSummation(position: number) {
    if (position === 0 || position === 1) return position + 1;

    return 2 ** position;
  }
}
