export const CUSTOM_TOOLBAR = [
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  ['bold', 'italic', 'underline', { script: 'super' }],

  [{ list: 'ordered' }, { list: 'bullet' }],

  [{ align: [] }],

  ['image'],

  ['clean']
];

export const THIRTY_SECONDS_IN_MILLISECONDS = 30000;
